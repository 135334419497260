var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ConfigureAlarmDialog',{attrs:{"dialogConfigureAlarm":_vm.dialogConfigureAlarm,"StoreObj":_vm.StoreObj,"configuration_type":_vm.configuration_type},on:{"clicked":_vm.dialogConfigureAlarmEmit}}),_c('RemoveAlarmConfigurationDialog',{attrs:{"dialogRemoveAlarmConfiguration":_vm.dialogRemoveAlarmConfiguration,"StoreObj":_vm.StoreObj},on:{"clicked":_vm.dialogRemoveAlarmConfigurationEmit}}),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('v-card',{staticClass:"elevation-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"heading1 PrimaryFontColor"},[_vm._v("Alarm Configuration")])])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),(_vm.configuration_type != 'CONNECTIONS')?_c('v-text-field',{staticClass:"field_height field_label_size fontSize mt-6 mr-2",attrs:{"dense":"","outlined":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-select',{staticClass:"field_height field_label_size fontSize maxWidth mt-6",attrs:{"dense":"","outlined":"","label":"Configuration Type","items":_vm.configuration_typeItems},model:{value:(_vm.configuration_type),callback:function ($$v) {_vm.configuration_type=$$v},expression:"configuration_type"}})],1)],1)],1),_c('v-card-text',{staticClass:"py-0"},[(_vm.configuration_type != 'CONNECTIONS')?_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.configuration_type == 'TAGS' ? _vm.alarmsHeaders : _vm.configuredPlantsHeaders,"items":_vm.configuration_type == 'TAGS' ? _vm.GetAllAlarmsList : _vm.GetAllConfiguredPlantsList},scopedSlots:_vm._u([{key:"item.tag_description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.tag_description))])]}},{key:"item.tag_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.tag_id))])]}},{key:"item.plant_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.plant_name))])]}},{key:"item.plant_tag_id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fontSize"},[_vm._v(_vm._s(item.plant_tag_id))])]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.color.toLowerCase()}},[_vm._v("mdi-checkbox-blank-circle")])]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"primary"},domProps:{"textContent":_vm._s(item.is_alarm_configured == true ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline')}})]}},{key:"item.StatusP",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"primary"},domProps:{"textContent":_vm._s(item.is_users_configured == 'NOT_YET' ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked')}})]}},{key:"item.Action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.checkItem(item, 'CONFIGURE')}}},[_c('v-icon',[_vm._v("mdi-cog")])],1),(_vm.configuration_type == 'TAGS' && item.is_alarm_configured == true)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","icon":"","color":"red"},on:{"click":function($event){return _vm.checkItem(item, 'REMOVE')}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.configuration_type == 'CONNECTIONS')?_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","align":"center"}},[_c('ConfigureConnectionAlarmCard')],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }