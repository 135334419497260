<template>
  <div>
    <ConfigureAlarmDialog :dialogConfigureAlarm="dialogConfigureAlarm" :StoreObj="StoreObj" :configuration_type="configuration_type" @clicked="dialogConfigureAlarmEmit" />
    <RemoveAlarmConfigurationDialog :dialogRemoveAlarmConfiguration="dialogRemoveAlarmConfiguration" :StoreObj="StoreObj" @clicked="dialogRemoveAlarmConfigurationEmit" />
    <Overlay :overlay="overlay" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Alarm Configuration</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field v-if="configuration_type != 'CONNECTIONS'" dense outlined class="field_height field_label_size fontSize mt-6 mr-2" label="Search" v-model="search"></v-text-field>
            <v-select dense outlined class="field_height field_label_size fontSize maxWidth mt-6" label="Configuration Type" v-model="configuration_type" :items="configuration_typeItems"></v-select>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-card outlined class="mt-3" v-if="configuration_type != 'CONNECTIONS'">
          <v-data-table
            dense
            :search="search"
            :headers="configuration_type == 'TAGS' ? alarmsHeaders : configuredPlantsHeaders"
            :items="configuration_type == 'TAGS' ? GetAllAlarmsList : GetAllConfiguredPlantsList"
          >
            <template v-slot:[`item.tag_description`]="{ item }">
              <div class="fontSize">{{ item.tag_description }}</div>
            </template>
            <template v-slot:[`item.tag_id`]="{ item }">
              <div class="fontSize">{{ item.tag_id }}</div>
            </template>
            <template v-slot:[`item.plant_name`]="{ item }">
              <div class="fontSize">{{ item.plant_name }}</div>
            </template>
            <template v-slot:[`item.plant_tag_id`]="{ item }">
              <div class="fontSize">{{ item.plant_tag_id }}</div>
            </template>
            <template v-slot:[`item.color`]="{ item }">
              <v-icon small :color="item.color.toLowerCase()">mdi-checkbox-blank-circle</v-icon>
            </template>
            <template v-slot:[`item.Status`]="{ item }">
              <v-icon small color="primary" v-text="item.is_alarm_configured == true ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"></v-icon>
            </template>
            <template v-slot:[`item.StatusP`]="{ item }">
              <v-icon small color="primary" v-text="item.is_users_configured == 'NOT_YET' ? 'mdi-checkbox-blank-outline' : 'mdi-checkbox-marked'"></v-icon>
            </template>
            <template v-slot:[`item.Action`]="{ item }">
              <v-btn small icon color="primary" @click="checkItem(item, 'CONFIGURE')">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
              <v-btn v-if="configuration_type == 'TAGS' && item.is_alarm_configured == true" small icon color="red" class="ml-2" @click="checkItem(item, 'REMOVE')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <v-card outlined class="mt-3" v-if="configuration_type == 'CONNECTIONS'">
          <v-row >
            <v-col cols="12" xs="12" sm="12" md="12" align="center">
              <ConfigureConnectionAlarmCard />
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import ConfigureAlarmDialog from "@/components/Settings/AlarmConfiguration/Dialogs/ConfigureAlarmDialog.vue";
import ConfigureConnectionAlarmCard from "@/components/Settings/AlarmConfiguration/Cards/ConfigureConnectionAlarmCard.vue";
import RemoveAlarmConfigurationDialog from "@/components/Settings/AlarmConfiguration/Dialogs/RemoveAlarmConfigurationDialog.vue";
import { GetAllAlarms } from "@/mixins/GetAllAlarms.js";
import { GetAllConfiguredPlants } from "@/mixins/GetAllConfiguredPlants.js";
export default {
  mixins: [GetAllAlarms, GetAllConfiguredPlants],
  components: {
    RemoveAlarmConfigurationDialog,
    ConfigureConnectionAlarmCard,
    ConfigureAlarmDialog,
    Overlay,
  },
  data: () => ({
    search: "",
    overlay: false,
    dialogConfigureAlarm: false,
    dialogConfigureColor: false,
    dialogRemoveAlarmConfiguration: false,
    StoreObj: {},
    alarmsHeaders: [
      { text: "Name", value: "tag_description" },
      { text: "Tag ID", value: "tag_id" },
      { text: "Status", value: "Status" },
      { text: "Action", value: "Action" },
    ],
    configuredPlantsHeaders: [
      { text: "Name", value: "plant_name" },
      { text: "Tag ID", value: "plant_tag_id" },
      { text: "Status", value: "StatusP" },
      { text: "Action", value: "Action" },
    ],
    configuration_type: "TAGS",
    configuration_typeItems: ["TAGS", "PLANTS", "CONNECTIONS"],
  }),
  watch: {
    configuration_type(value) {
      if (value && value != "") {
        if (value == "TAGS") {
          this.GetAllAlarmsListMethod(this.$store.getters.get_asset_object);
        } else if (value == "PLANTS") {
          this.GetAllConfiguredPlantsListMethod(this.$store.getters.get_asset_object);
        }
      }
    },
  },
  mounted() {
    this.GetAllAlarmsListMethod(this.$store.getters.get_asset_object);
  },
  methods: {
    dialogConfigureColorEmit(Toggle) {
      this.dialogConfigureColor = false;
      if (Toggle == 2) {
        this.GetAllAlarmsListMethod(this.$store.getters.get_asset_object);
      }
    },
    dialogRemoveAlarmConfigurationEmit(Toggle) {
      this.dialogRemoveAlarmConfiguration = false;
      if (Toggle == 2) {
        this.GetAllAlarmsListMethod(this.$store.getters.get_asset_object);
      }
    },
    dialogConfigureAlarmEmit(Toggle) {
      this.dialogConfigureAlarm = false;
      if (Toggle == 2) {
        if (this.configuration_type == "TAGS") {
          this.GetAllAlarmsListMethod(this.$store.getters.get_asset_object);
        } else if (this.configuration_type == "PLANTS") {
          this.GetAllConfiguredPlantsListMethod(this.$store.getters.get_asset_object);
        }
      }
    },
    checkItem(item, Action) {
      this.StoreObj = item;
      switch (Action) {
        case "CONFIGURE":
          this.dialogConfigureAlarm = true;
          break;
        case "REMOVE":
          this.dialogRemoveAlarmConfiguration = true;
          break;
      }
    },
  },
};
</script>
