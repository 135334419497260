import { API } from "aws-amplify";
export const GetConnectionAlarmConfiguration = {
  data() {
    return {
      GetConnectionAlarmConfigurationList: {},
      overlay: false,
      RouterList: [],
      user_type: "",
    };
  },
  methods: {
    async GetConnectionAlarmConfigurationMethod() {
      var self = this;
      const path = "getalarmconfigurationforconnectionloss";
      const myInit = {
        body: {
          command: "getAlarmConfigurationForConnectionLoss",
          organization_id: self.$store.getters.get_current_user_details.organization_id,
          asset_id: self.$store.getters.get_asset_object.asset_id,
          user_id: self.$store.getters.get_current_user_details.user_id,
        },
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            self.GetConnectionAlarmConfigurationList = response.data.items;
            self.configure_alarm.alarm_message = self.GetConnectionAlarmConfigurationList[0].alarm_message;
            self.configure_alarm.configured_users = self.GetConnectionAlarmConfigurationList[0].configured_users;
            self.configure_alarm.job_reopenable_users = self.GetConnectionAlarmConfigurationList[0].job_reopenable_users;
            self.configure_alarm.is_escalation_enabled = self.GetConnectionAlarmConfigurationList[0].is_escalation_enabled;
            self.configure_alarm.escalation_delay = self.GetConnectionAlarmConfigurationList[0].escalation_delay;
            self.configure_alarm.escalated_to_users = self.GetConnectionAlarmConfigurationList[0].escalated_to_users;
          } else {
            self.GetConnectionAlarmConfigurationList = [];
            self.noDataText = response.errorMessage;
            self.overlay = false;
          }
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
};
