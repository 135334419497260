<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-card outlined class="elevation-0 ma-3" max-width="800px">
      <div align="left" class="font-weight-bold pt-4 pl-4">Connection Alarm Configuration :</div>
      <v-card-text class="pb-0">
        <v-form ref="form" class="mt-4">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-textarea dense outlined class="FontSize" rows="2" label="Alarm Message" v-model="configure_alarm.alarm_message"></v-textarea>
              <v-select
                dense
                outlined
                multiple
                label="User"
                class="field_height field_label_size FontSize"
                item-text="user_name"
                item-value="user_id"
                :items="GetAllUsersList"
                :rules="[(v) => !!v || 'Required']"
                v-model="configure_alarm.configured_users"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip x-small v-if="index === 0">
                    <span>{{ item.user_name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"> (+{{ configure_alarm.configured_users.length - 1 }} others) </span>
                </template>
              </v-select>
              <v-select
                dense
                outlined
                multiple
                label="Re-Openable Users"
                class="field_height field_label_size FontSize"
                item-text="user_name"
                item-value="user_id"
                :items="GetAllUsersList"
                :rules="[(v) => !!v || 'Required']"
                v-model="configure_alarm.job_reopenable_users"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip x-small v-if="index === 0">
                    <span>{{ item.user_name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"> (+{{ configure_alarm.job_reopenable_users.length - 1 }} others) </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-checkbox dense class="ma-0 pa-0" v-model="configure_alarm.is_escalation_enabled" label="Is Escalation Enabled ?"></v-checkbox>
              <v-select
                dense
                outlined
                v-if="configure_alarm.is_escalation_enabled == true"
                label="Escalation Delay (In Minutes)"
                class="field_height field_label_size FontSize"
                :items="escalationDelayItems"
                :rules="[(v) => !!v || 'Required']"
                v-model="configure_alarm.escalation_delay"
              >
              </v-select>
              <v-select
                dense
                outlined
                v-if="configure_alarm.is_escalation_enabled == true"
                label="Escalated Users"
                class="field_height field_label_size FontSize"
                item-text="user_name"
                item-value="user_id"
                multiple
                :items="GetAllUsersList"
                :rules="[(v) => !!v || 'Required']"
                v-model="configure_alarm.escalated_to_users"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0 pb-4">
        <v-spacer></v-spacer>
        <v-btn small class="mr-2 borderRadius" color="primary" :loading="loading" @click="validateFormMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { GetAllUsers } from "@/mixins/GetAllUsers.js";
import { GetConnectionAlarmConfiguration } from "@/mixins/GetConnectionAlarmConfiguration.js";
import { API } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import CountryList from "@/JsonFiles/CountryList.json";
export default {
  mixins: [GetAllUsers, GetConnectionAlarmConfiguration],
  props: {
    configuration_type: String,
    dialogConfigureAlarm: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    user_type: "",
    userTypeItems: [
      { text: "Admin", value: "ADMIN" },
      { text: "Normal User", value: "NORMAL_USER" },
    ],
    loading: false,
    configure_alarm: {
      alarm_message: "",
      configured_users: [],
      job_reopenable_users: [],
      should_have_job_number: false,
      is_analysis_required: false,
      is_escalation_enabled: false,
      escalation_delay: 0,
      escalated_to_users: "",
      alarm_frequency: "",
    },
    AlarmsFrequencies: [],
    escalationDelayItems: [],
    SnackBarComponent: {},
    CountryList: [],
  }),
  watch: {},
  mounted() {
    this.GetAllUsersListMethod();
    this.GetConnectionAlarmConfigurationMethod();
    this.CountryList = CountryList;
    this.escalationDelayItems = [];
    let i = 0;
    while (i < 60) {
      i++;
      this.escalationDelayItems.push(i);
    }
  },
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.allCommonMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Inputs",
        };
      }
    },
    async allCommonMethod() {
      var mutationname = "configurealarmforconnectionloss";
      var inputParams = {
        command: "configureAlarmForConnectionLoss",
        organization_id: this.$store.getters.get_current_user_details.organization_id,
        asset_id: this.$store.getters.get_asset_object.asset_id,
        user_id: this.$store.getters.get_current_user_details.user_id,
        alarm_message: this.configure_alarm.alarm_message,
        configured_users: this.configure_alarm.configured_users,
        job_reopenable_users: this.configure_alarm.job_reopenable_users,
        is_escalation_enabled: true,
        escalation_delay: 1,
        escalated_to_users: this.configure_alarm.escalated_to_users,
      };

      var self = this;
      self.loading = true;
      const path = mutationname;
      const myInit = {
        body: inputParams,
      };
      API.post("CHAINPORTAL", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: response.Status_Message,
            };
            this.loading = false;
            this.GetConnectionAlarmConfigurationMethod();
          } else {
            this.loading = false;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorType,
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>
